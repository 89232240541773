import React from 'react';

const IconMobile = () => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
    stroke="currentColor"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
    <g id="SVGRepo_iconCarrier">
      <g>
        <g>
          <path d="M362.264,0H149.736c-30.134,0-54.65,24.521-54.65,54.66v19.157v361.724v21.8c0,30.139,24.516,54.66,54.65,54.66h212.528 c30.134,0,54.65-24.521,54.65-54.66v-21.8V73.817V54.66C416.914,24.521,392.398,0,362.264,0z M396.016,457.34 c0,18.617-15.141,33.762-33.752,33.762H149.736c-18.612,0-33.752-15.145-33.752-33.762V445.99h280.033V457.34z M396.016,425.092 H115.984V84.266h280.033V425.092z M396.016,63.368H115.984V54.66c0-18.616,15.141-33.762,33.752-33.762h212.528 c18.612,0,33.752,15.146,33.752,33.762V63.368z"></path>
        </g>
      </g>
      <g>
        <g>
          <path d="M256,448.073c-11.287,0-20.469,9.184-20.469,20.472s9.182,20.472,20.469,20.472s20.47-9.184,20.47-20.472 C276.47,457.257,267.287,448.073,256,448.073z M256,468.971c-0.236,0-0.428-0.192-0.428-0.426c0-0.234,0.191-0.426,0.428-0.426 s0.429,0.192,0.429,0.426C256.429,468.779,256.236,468.971,256,468.971z"></path>
        </g>
      </g>
      <g>
        <g>
          <path d="M272.367,152.901c-5.667-1.111-11.154,2.579-12.265,8.242l-32.317,164.698c-1.111,5.663,2.579,11.154,8.241,12.265 c0.679,0.134,1.356,0.197,2.024,0.197c4.894,0,9.264-3.457,10.242-8.439l32.317-164.7 C281.719,159.503,278.03,154.012,272.367,152.901z"></path>
        </g>
      </g>
      <g>
        <g>
          <path d="M354.093,245.746c-0.008-0.171-0.008-0.343-0.025-0.514c-0.016-0.161-0.046-0.321-0.069-0.482 c-0.024-0.171-0.042-0.343-0.074-0.512c-0.032-0.165-0.079-0.327-0.12-0.491c-0.04-0.163-0.072-0.327-0.119-0.488 c-0.048-0.162-0.112-0.32-0.167-0.479c-0.055-0.16-0.103-0.321-0.167-0.479c-0.059-0.144-0.132-0.282-0.196-0.424 c-0.077-0.168-0.147-0.34-0.234-0.505c-0.063-0.119-0.139-0.233-0.207-0.35c-0.104-0.181-0.204-0.365-0.32-0.54 c-0.065-0.098-0.142-0.19-0.21-0.286c-0.134-0.188-0.264-0.378-0.412-0.559c-0.06-0.073-0.13-0.14-0.191-0.212 c-0.168-0.195-0.335-0.391-0.52-0.576l-48.454-48.506c-4.078-4.082-10.695-4.087-14.778-0.007 c-4.082,4.078-4.086,10.695-0.008,14.777l41.077,41.121l-40.35,40.393c-4.077,4.082-4.074,10.699,0.008,14.778 c2.04,2.038,4.713,3.056,7.384,3.056c2.676,0,5.352-1.022,7.393-3.065l47.727-47.778c0.185-0.185,0.352-0.381,0.52-0.576 c0.062-0.072,0.132-0.139,0.191-0.212c0.147-0.181,0.278-0.371,0.412-0.559c0.068-0.096,0.145-0.188,0.21-0.286 c0.116-0.176,0.215-0.359,0.32-0.54c0.068-0.117,0.144-0.231,0.207-0.35c0.087-0.165,0.157-0.335,0.234-0.505 c0.065-0.141,0.138-0.28,0.196-0.424c0.064-0.157,0.112-0.319,0.167-0.479c0.055-0.16,0.119-0.318,0.167-0.479 c0.048-0.161,0.079-0.326,0.119-0.488c0.04-0.164,0.088-0.326,0.12-0.491c0.032-0.169,0.05-0.342,0.074-0.512 c0.023-0.161,0.053-0.32,0.069-0.482c0.017-0.17,0.017-0.343,0.025-0.514c0.007-0.163,0.023-0.325,0.023-0.488 C354.115,246.071,354.1,245.909,354.093,245.746z"></path>
        </g>
      </g>
      <g>
        <g>
          <path d="M220.125,286.627l-41.076-41.121l40.349-40.393c4.078-4.083,4.074-10.699-0.008-14.777 c-4.083-4.079-10.698-4.075-14.778,0.007l-47.726,47.778c-0.179,0.179-0.34,0.369-0.503,0.557 c-0.068,0.078-0.143,0.151-0.209,0.231c-0.136,0.167-0.255,0.343-0.379,0.516c-0.079,0.111-0.168,0.217-0.242,0.33 c-0.102,0.156-0.189,0.318-0.282,0.477c-0.082,0.138-0.17,0.273-0.245,0.414c-0.074,0.141-0.133,0.286-0.2,0.429 c-0.077,0.166-0.162,0.329-0.231,0.498c-0.054,0.133-0.093,0.27-0.141,0.403c-0.066,0.184-0.138,0.367-0.193,0.554 c-0.043,0.142-0.07,0.287-0.106,0.432c-0.046,0.183-0.098,0.364-0.134,0.548c-0.031,0.16-0.046,0.321-0.07,0.482 c-0.025,0.171-0.057,0.341-0.074,0.512c-0.016,0.168-0.017,0.336-0.024,0.506c-0.007,0.165-0.023,0.33-0.023,0.496 s0.016,0.331,0.023,0.496c0.008,0.168,0.008,0.338,0.024,0.506c0.016,0.168,0.048,0.335,0.072,0.504 c0.024,0.164,0.04,0.328,0.071,0.49c0.033,0.174,0.084,0.346,0.126,0.519c0.038,0.154,0.068,0.308,0.113,0.46 c0.05,0.171,0.117,0.338,0.177,0.507c0.053,0.151,0.098,0.303,0.158,0.45c0.062,0.15,0.138,0.297,0.207,0.445 c0.074,0.161,0.141,0.325,0.225,0.483c0.066,0.124,0.145,0.242,0.215,0.365c0.101,0.177,0.198,0.354,0.311,0.526 c0.065,0.098,0.142,0.19,0.21,0.286c0.134,0.188,0.264,0.378,0.412,0.559c0.06,0.073,0.13,0.14,0.191,0.212 c0.168,0.195,0.335,0.391,0.52,0.576l48.454,48.506c2.041,2.043,4.717,3.065,7.393,3.065c2.673,0,5.345-1.019,7.384-3.056 C224.199,297.327,224.202,290.711,220.125,286.627z"></path>
        </g>
      </g>
    </g>
  </svg>
);

export default IconMobile;
